import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout";
import Container from "../components/container"

// Utilities
import kebabCase from "lodash/kebabCase"

// Components
import {Helmet} from "react-helmet"
import {Link, graphql} from "gatsby"
import Metatags from "../components/Metatags";

const CategoryPage = ({
        data: {
            allWpCategory: {nodes, totalCount},
            site: {
                siteMetadata: {title},
            },
        },
    }) => (
    <Layout>
         <Metatags
            title="Effective Website Conversion Tool | Optinly"
            description = "Optinly is a website pop-up builder that boost conversions. Explore our category page for valuable insights and resources."
            keywords= {[`exit popup`, `popup maker`, `free exit popup generator`, `email optins Features Page`]}
            url = "https://www.optinly.com"
            pathname = "/category/"
        />
        <Container>
            <div>
                <Helmet title={title}/>
                <div>
                    <h1>Category</h1>
                    <ul>
                        {nodes.map(tag => (
                            <li key={tag.name}>
                                <Link to={tag.uri}>
                                    {tag.name} ({totalCount})
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Container>
    </Layout>
)

CategoryPage.propTypes = {
    data: PropTypes.shape({
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                title: PropTypes.string.isRequired,
            }),
        }),
    }),
}

export default CategoryPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allWpCategory(limit: 20) {
        nodes {
            name
            slug
            uri
            count
        }
        totalCount
    }
  }
`